
import { defineComponent, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';

import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import User from '@/classes/models/User';
import CustomError from '@/classes/models/CustomError';
import { FormHandler } from '@/classes/forms/FormHandler';
import RegisterFormFields from '@/constants/FormFields/register';
import { authModule } from '@/store/modules/auth';

import Captcha from '@/components/UI/Captcha.vue';
import DreenInput from '@/components/UI/Input.vue';
import { FormFields } from '@/interfaces/Input';

export default defineComponent({
  components: {
    Captcha,
    DreenInput,
    Dialog,
    Button,
  },

  setup() {
    const loading = ref(false);
    const item = reactive(new User({}));
    const fields = reactive<FormFields>({ ...RegisterFormFields });

    const formHandler = new FormHandler(fields, item);
    const toast = useToast();
    const router = useRouter();

    const registerHandler = async () => {
      loading.value = true;
      const valid = await formHandler.checkValidations();
      if (valid) {
        try {
          await authModule.actions.register(item);
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail:
              'Сообщение отправлено. Подтвердите Ваш email, чтобы активировать аккаунт!',
            life: 5000,
          });
          await router.push('/login');
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 3000, toast);
          } else {
            console.log(error);
          }
        }
      }
      loading.value = false;
    };

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const setItem = (itm: User) => {
      Object.assign(item, itm);
      formHandler.setObjectToValidate(item);
    };

    const resetFormHandler = () => {
      setItem(new User({}));
      resetValidations();
    };

    return {
      item,
      fields,
      loading,
      formHandler,
      registerHandler,
      resetFormHandler,
    }
  },
});
