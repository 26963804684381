<template>
  <register-form />
</template>

<script>
import RegisterForm from '../components/Authentication/RegisterForm.vue';

export default {
  components: {
    'register-form': RegisterForm,
  },
}
</script>

<style></style>
