import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  firstName: {
    title: 'Ваше имя',
    type: 'input',
  },
  lastName: {
    title: 'Ваша Фамилия',
    type: 'input',
  },
  email: {
    title: 'Email',
    type: 'input',
  },
  phone: {
    title: 'Телефон',
    type: 'input',
  },
  password: {
    title: 'Пароль',
    type: 'input',
    variableType: 'password',
  },
  password2: {
    title: 'Подтвердите пароль',
    type: 'input',
    variableType: 'password',
  },
  captchaToken: {
    title: 'Введите код с картинки',
    type: 'input',
  },
  acceptTerms: {
    title: 'Я принимаю условия использования',
    type: 'checkbox',
  },
};

export default fields;
