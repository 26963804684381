import { IsNotEmpty, IsEmail, Equals, Length } from 'class-validator';
import { IsEqualTo } from '@/utils/custom-validators';

import { VALID_ERRORS } from '@/constants/errors';

interface UserInterface {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  password?: string;
  password2?: string;
  acceptTerms?: boolean;
  captchaGuid?: string;
  captchaToken?: string;
}

export default class User implements UserInterface {
  id?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 50, { message: VALID_ERRORS.length(3, 50) })
  firstName?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 50, { message: VALID_ERRORS.length(3, 50) })
  lastName?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @IsEmail({}, { message: VALID_ERRORS.isEmail })
  @Length(5, 100, { message: VALID_ERRORS.length(5, 100) })
  email?: string;
  phone?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(5, 50, { message: VALID_ERRORS.length(5, 50) })
  password?: string;
  @IsEqualTo('password', { message: VALID_ERRORS.passwordMatches })
  password2?: string;
  @Equals(true, { message: VALID_ERRORS.acceptTerms })
  acceptTerms?: boolean;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  captchaGuid?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  captchaToken?: string;

  constructor(user: UserInterface) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.phone = user.phone;
    this.password = user.password;
    this.password2 = user.password2;
    this.acceptTerms = user.acceptTerms;
    this.captchaGuid = user.captchaGuid;
    this.captchaToken = user.captchaToken;
  }

  replaceBy(data: UserInterface) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.password = data.password;
    this.password2 = data.password2;
    this.acceptTerms = data.acceptTerms;
    this.captchaGuid = data.captchaGuid;
    this.captchaToken = data.captchaToken;
  }
}
